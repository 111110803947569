import { Typography } from "@mui/material";
import * as React from "react";
import PropTypes from "prop-types";

const body = {
  marginBottom: "40px",
};

const Paragraph = ({ text }) => {
  return (
    <Typography variant="body1" style={body}>
      {text}
    </Typography>
  );
};

Paragraph.propTypes = {
  text: PropTypes.string.isRequired,
};
export default Paragraph;

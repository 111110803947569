import React from "react";
import VerticalMenu from "../../components/VerticalMenu";
import HorizontalMenu from "../../components/HorizontalMenu";
import Container from "@mui/material/Container";
import {
  getSingleOrderResponseJsonV2,
  acceptQuoteEndpoints,
  quoteEndpoints,
  acceptQuoteExample,
  getQuoteAcceptResponse,
} from "../../constants";
import CodeContainer from "../../components/CodeContainer";
import Helmet from "react-helmet";
import favicon from "../../images/favicon.ico";
import JsonContainer from "../../components/JsonContainer";
import {
  codeFont,
  listItems,
  listStyle,
  pageStyles,
  pageContent,
} from "../../styleConstants";
import AlertInfo from "../../components/PageComponents/AlertInfo";
import Paragraph from "../../components/PageComponents/Paragraph";
import ParagraphTitle from "../../components/PageComponents/ParagraphTitle";
import PageTitle from "../../components/PageComponents/PageTitle";

const IndexPage = () => {
  return (
    <main style={pageStyles}>
      <HorizontalMenu />
      <div style={pageContent}>
        <VerticalMenu page={4} />

        <Container maxWidth="md" style={{ wordWrap: "break-word" }}>
          <PageTitle text="Accept or reject a quote of your order" />
          <AlertInfo text="This is only available in version 2 of the GLOBHE order API" />

          <Paragraph
            text="Once you a quote for your order is ready, you can accept or reject
            this quote. To get details about your quote, you should use the
            getorder endpoint to fetch information about a specific order."
          />
          <ParagraphTitle text="Response structure when using the getorder endpoint" />
          <ul style={listStyle}>
            {acceptQuoteEndpoints.map(({ code, description }) => (
              <li key={code} style={listItems}>
                <code style={codeFont}>{code}</code> : {description}
              </li>
            ))}
          </ul>
          <ParagraphTitle text="Quote information structure" />
          <ul style={listStyle}>
            {quoteEndpoints.map(({ code, description }) => (
              <li key={code} style={listItems}>
                <code style={codeFont}>{code}</code> : {description}
              </li>
            ))}
          </ul>
          <ParagraphTitle text="Example Request to get details of a quote" />
          <CodeContainer text="POST https://us-central1-globhe-app.cloudfunctions.net/api/v2/getorder/20230628arte" />
          <ParagraphTitle text="Example Response" />
          <JsonContainer text={getSingleOrderResponseJsonV2} />
          <br />

          <ParagraphTitle text="How to accept or reject a quote" />
          <Paragraph text="You can accept or reject a quote with the request below. Make sure you copy your orderId and quoteId from the getorder enpoint correctly." />
          <CodeContainer text="POST https://us-central1-globhe-app.cloudfunctions.net/api/v2/order/:orderId/quote/:quoteId" />
          <Paragraph text="The accept or reject quote endpoint must include the following item:" />
          <ul style={listStyle}>
            <li style={listItems}>
              <code style={codeFont}>status</code> : Insert &apos;accept&apos;
              or &apos;reject&apos; to either accept or reject your quote
              respectively
            </li>
          </ul>
          <ParagraphTitle text="Example request" />
          <CodeContainer text="POST https://us-central1-globhe-app.cloudfunctions.net/api/v2/order/20230628arte/quote/20230628arte-1" />
          <JsonContainer text={acceptQuoteExample} />

          <ParagraphTitle text="Example Response" />
          <JsonContainer text={getQuoteAcceptResponse} />
        </Container>
      </div>

      <Helmet>
        <meta charSet="utf-8" />
        <title>GLOBHE&apos;s developers docs</title>
        <link rel="icon" href={favicon} />
      </Helmet>
    </main>
  );
};

export default IndexPage;

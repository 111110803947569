import * as React from "react";
import PropTypes from "prop-types";
import theme from "../theme";
import Box from "@mui/material/Box";
const styles = {
  container: {
    width: "100%",
    background: theme.palette.black.paleSilver,
    margin: "20px 0",
  },
  codeSimple: {
    fontFamily: "'Inconsolata','Courier New',Courier,monospace",
    fontSize: ".875rem",
    padding: "2px 6px",
    width: "100%",
    wordWrap: "break-word",
    overflowX: "auto",
    whiteSpace: "pre-wrap",
  },
  red: {
    color: "#f0506e",
  },
};

const CodeContainer = ({ text, red }) => {
  return (
    <Box style={styles.container}>
      <code
        style={
          red ? { ...styles.red, ...styles.codeSimple } : styles.codeSimple
        }
      >
        {text}{" "}
      </code>
    </Box>
  );
};
CodeContainer.propTypes = {
  text: PropTypes.string.isRequired,
  red: PropTypes.bool,
};
CodeContainer.defaultProps = {
  red: false,
};
export default CodeContainer;
